<!--  -->
<template>
  <div style="background-color: #f7f7f7">
    <div class="title_div">
      <img
        src="../assets/icon/back.png"
        style="height:20px;width:20px;margin-left:10px;margin-top:1.5vw;"
        @click="goBack()"
      />
      <div>处方明细</div>
      <div style="width:40px;"></div>
    </div>
    <div>
      <img  :src="picture" style="width:100%;" alt="">
      
    </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
        carList: [
       
      ],
      picture:''
    }
  },
 mounted(){
 this.hfEnter()
  console.log(this.$route.query.name,"row.name");
    // console.log(this.$route.query.lxdh,"row.name");
    
  

 },
  methods: {
    hfEnter(){
      // https://babb.boaiyiyao.com/api
      this.$axios
        .get(
          "https://babb.boaiyiyao.com/api" +
            `/renren-fast/app/info/getRecipePng/${this.$route.query.name}`,
          {}
        )
        .then((res) => {
          console.log(res.data.code,"11");
          if(res.data.code==0){
          this.picture=res.data.data
            
          }
    
        });
    },
  goBack(){
    this.$router.go(-1);
  }

  }
}

</script>
<style  scoped>
.title_div {
  height: 9vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  /* line-height:12vh;
  text-align: center;
  font-size:4.6vw; */
;
  /* background: #3F98EB; */
  font-size: 4.6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>